import { createProvider, createCustomContext } from './createProvider'
import { createUseTracked } from './createUseTrackedState'

export const createContainer = useValue => {
  const context = createCustomContext()
  return {
    Provider: createProvider(context, useValue),
    useTracked: createUseTracked(context)
  }
}
