import { scaleLinear } from 'd3'

export const colorMin = '#002060'
export const colorOne = '#9AA6AC'
export const colorMax = '#C0172C'
export const globalFont =
  "'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif"

export const RANGE_MIN = 0.8
export const RANGE_AVG = 1
export const RANGE_MAX = 2

export function makeColorScale(
  rangeMin = RANGE_MIN,
  rangAvg = RANGE_AVG,
  rangeMax = RANGE_MAX
) {
  const colorDomain = [rangeMin, rangAvg, rangeMax]
  const colorRange = [colorMin, colorOne, colorMax]

  const color = scaleLinear()
    .domain(colorDomain)
    .range(colorRange)
    .clamp(true)

  return color
}

export const capitalize = str =>
  str
    .split(' ')
    .map(s => `${s.charAt(0).toUpperCase()}${s.slice(1).toLowerCase()}`)
    .join(' ')

export const formatNumber = n => {
  const base = Math.floor(Math.log(Math.abs(n)) / Math.log(1000))
  const suffix = 'KMB'[base - 1]
  return suffix
    ? String(n / Math.pow(1000, base)).substring(0, 3) + suffix
    : '' + n
}

export const equal = (a, b) => JSON.stringify(a) === JSON.stringify(b)

export function dethunkify(value) {
  return typeof value === 'function' ? value() : value
}

export const nth = number =>
  `${number}${
  number === 1 ? 'st' : number === 2 ? 'nd' : number === 3 ? 'rd' : 'th'
  }`

export const newLevels = {
  Intenders: 'Est plutôt intéressé',
  Interested: 'Est assez intéressé',
  'Highly Interested': 'Se sent concerné',
  Engaged: 'Se sent impliqué'
}

export const newLevelz = {
  Intenders: 'plutôt intéressés',
  Interested: 'assez intéressés',
  'Highly Interested': 'concernés',
  Engaged: 'impliqués'
}

export const images = {
  'Bien etre':
    'https://storage.googleapis.com/udm-assets/new-pictos/Bien-être.png',
  'Consommateurs a laction':
    "https://storage.googleapis.com/udm-assets/new-pictos/Consommateur à l'action.png",
  'Consommation ethique':
    'https://storage.googleapis.com/udm-assets/new-pictos/Consommation éthique.png',
  'Culture Maker':
    'https://storage.googleapis.com/udm-assets/new-pictos/Culture Maker.png',
  'Demarche Qualite':
    'https://storage.googleapis.com/udm-assets/new-pictos/Démarche Qualité.png',
  Detox: 'https://storage.googleapis.com/udm-assets/new-pictos/Détox.png',
  'Eco mobilite':
    'https://storage.googleapis.com/udm-assets/new-pictos/Eco-mobilité.png',
  'Economie collaborative':
    'https://storage.googleapis.com/udm-assets/new-pictos/Economie collaborative.png',
  'Energies renouvelables':
    'https://storage.googleapis.com/udm-assets/new-pictos/Energies renouvelables.png',
  'Impact environnemental':
    'https://storage.googleapis.com/udm-assets/new-pictos/Impact environnemental.png',
  Naturalite:
    'https://storage.googleapis.com/udm-assets/new-pictos/Naturalité.png',
  RSE: 'https://storage.googleapis.com/udm-assets/new-pictos/RSE.png',
  'Seconde vie':
    'https://storage.googleapis.com/udm-assets/new-pictos/Seconde vie.png',
  SuperFood:
    'https://storage.googleapis.com/udm-assets/new-pictos/SuperFood.png',
  Surconsommation:
    'https://storage.googleapis.com/udm-assets/new-pictos/Surconsommation.png',
  'Zero dechet':
    'https://storage.googleapis.com/udm-assets/new-pictos/Zéro déchet.png'
}

export const brands = {
  Sprite: 'https://cdn.worldvectorlogo.com/logos/sprite-2.svg',
  Nescafe: 'https://cdn.worldvectorlogo.com/logos/nescafe.svg',
  Subway: 'https://cdn.worldvectorlogo.com/logos/subway-12.svg',
  Verizon: 'https://cdn.worldvectorlogo.com/logos/verizon.svg',
  Visa: 'https://cdn.worldvectorlogo.com/logos/visa-5.svg',
  Marlboro: 'https://cdn.worldvectorlogo.com/logos/marlboro.svg',
  'Johnnie Walker': 'https://cdn.worldvectorlogo.com/logos/johnnie-walker-3.svg',
  'Coca Cola': 'https://cdn.worldvectorlogo.com/logos/coca-cola.svg'
}
