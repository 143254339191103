import React from 'react'
import { Dropdown, Image, Menu } from 'semantic-ui-react'
import { useResource, useStore } from 'lib/hooks'
import { images, formatNumber, newLevels } from 'lib/utils'

export const PopulationDropdown = () => {
  const [{ pop, lvl }, dispatch] = useStore()
  const { data } = useResource(`/api/taxopop?lvl=${lvl}`)
  const options = data.data.map(d => ({
    key: d.pop,
    value: d.pop,
    text: d.pop,
    image: { avatar: true, src: images[d.pop] }
  }))

  const handleOnChange = (e, d) =>
    dispatch({ type: 'SET_POP', payload: d.value })

  return (
    <>
      <Menu.Item>
        <Image src={images[pop]} size='mini' className='circular inline' />
        {formatNumber(data.data.filter(d => d.pop === pop)[0].vol)}
      </Menu.Item>
      <Menu.Item>
        <Dropdown
          placeholder='Select population'
          options={options}
          value={pop}
          search
          selection
          onChange={handleOnChange}
        />
      </Menu.Item>
    </>
  )
}

export const LevelDropdown = () => {
  const [{ lvl }, dispatch] = useStore()
  // const { data } = useResource('/api/lvl')

  const options = Object.entries(newLevels).map(([key, value]) => ({
    key: key,
    value: key,
    text: value
  }))
  const handleOnChange = (e, d) =>
    dispatch({ type: 'SET_LVL', payload: d.value })

  return (
    <Menu.Item>
      <Dropdown
        placeholder='Select level'
        options={options}
        value={lvl}
        search
        selection
        onChange={handleOnChange}
      />
    </Menu.Item>
  )
}
