import React from 'react'
import { Popup } from 'semantic-ui-react'

const MyPopup = ({ fixed, children }) => (
  <Popup
    basic
    wide='very'
    trigger={
      <div className='ui basic popup transition visible'>
        <div className='content' style={{ maxWidth: fixed && '400px' }}>{children}</div>
      </div>
    }
    content={children}
  />
)

export default MyPopup
