import React, { Suspense } from 'react'
import { Loader, Header, Segment } from 'semantic-ui-react'
import { ErrorBoundary } from 'components/ui'

const Warning = () => (
  <Header as='h3' color='grey' textAlign='center'>
    Pas de données
  </Header>
)

const SpiralLoader = () => (
  <Loader active inline='centered'>
    Chargement
  </Loader>
)

const Placeholder = ({
  fallbackError = <Warning />,
  fallbackSuspense = <SpiralLoader />,
  children,
  ...rest
}) => {
  return (
    <ErrorBoundary fallback={fallbackError}>
      <Suspense fallback={fallbackSuspense}>
        <Segment placeholder {...rest} className="transition">
          {children}
        </Segment>
      </Suspense>
    </ErrorBoundary>
  )
}

export default Placeholder
