// import React, { useContext, useLayoutEffect, useReducer } from 'react'
// import { Store, reducer } from 'lib/context'

// const Provider = ({ children }) => {
//   const globalStore = useContext(Store)
//   const localStore = JSON.parse(window.localStorage.getItem('globalStore'))
//   const [state, dispatch] = useReducer(reducer, localStore || globalStore)

//   useLayoutEffect(() => {
//     window.localStorage.setItem('globalStore', JSON.stringify(state))
//   }, [state])

//   return <Store.Provider value={{ state, dispatch }}>{children}</Store.Provider>
// }

// export default Provider

import { Provider } from 'lib/context'
export default Provider
