const requests = {}

function resolveRequest({ requestKey, res, err }) {
  const handlers = requests[requestKey] || []

  handlers.forEach(handler => {
    if (res) {
      handler.resolve(res)
    } else {
      handler.reject(err)
    }
  })

  requests[requestKey] = null
}

export function fetchDedupe(input, init = {}, dedupeOptions) {
  let opts, initToUse
  if (dedupeOptions) {
    opts = dedupeOptions
    initToUse = init
  } else if (init.responseType) {
    opts = init
    initToUse = {}
  } else {
    opts = {}
    initToUse = init
  }

  const { requestKey, responseType = '', dedupe = true } = opts

  const requestKeyToUse =
    requestKey ||
    [
      input.url || input,
      (initToUse.method || input.method || '').toUpperCase(),
      responseType,
      initToUse.body || input.body || ''
    ].join('||')

  let proxyReq
  if (dedupe) {
    if (!requests[requestKeyToUse]) {
      requests[requestKeyToUse] = []
    }

    const handlers = requests[requestKeyToUse]
    const requestInFlight = Boolean(handlers.length)
    const requestHandler = {}
    proxyReq = new Promise((resolve, reject) => {
      requestHandler.resolve = resolve
      requestHandler.reject = reject
    })

    handlers.push(requestHandler)

    if (requestInFlight) {
      return proxyReq
    }
  }

  const request = window.fetch(input, initToUse).then(
    res => {
      let responseTypeToUse
      if (responseType instanceof Function) {
        responseTypeToUse = responseType(res)
      } else if (responseType) {
        responseTypeToUse = responseType
      } else if (res.status === 204) {
        responseTypeToUse = 'text'
      } else {
        responseTypeToUse = 'json'
      }

      return res[responseTypeToUse]().then(
        data => {
          res.data = data

          if (dedupe) {
            resolveRequest({ requestKey: requestKeyToUse, res })
          } else {
            return res
          }
        },
        () => {
          res.data = null

          if (dedupe) {
            resolveRequest({ requestKey: requestKeyToUse, res })
          } else {
            return res
          }
        }
      )
    },
    err => {
      if (dedupe) {
        resolveRequest({ requestKey: requestKeyToUse, err })
      } else {
        return Promise.reject(err)
      }
    }
  )

  if (dedupe) {
    return proxyReq
  } else {
    return request
  }
}
