import { useReducer } from 'react'
import { createContainer } from './createContainer'

const context = {
  taxo: 'Tendances de consommation responsable',
  pop: 'SuperFood',
  lvl: 'Interested',
  ref: 'CountryPop',
  uiPanel: 'Spiral',
  uiTour: false
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_TAXO':
      return { ...state, taxo: action.payload }

    case 'SET_POP':
      return { ...state, pop: action.payload }

    case 'SET_LVL':
      return { ...state, lvl: action.payload }

    case 'SET_REF':
      return { ...state, ref: action.payload }

    case 'SET_UI_PANEL':
      return { ...state, uiPanel: action.payload }

    case 'TOGGLE_UI_TOUR':
      return { ...state, uiTour: !state.uiTour }

    default:
      return state
  }
}

const useValue = ({ reducer, initialState }) =>
  useReducer(reducer, initialState)
const { Provider, useTracked } = createContainer(useValue)

Provider.displayName = 'Store'

export { Provider, useTracked, context, reducer }
