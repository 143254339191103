import { useState, useLayoutEffect } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

const useSize = ref => {
  const [size, setSize] = useState({ width: 0, height: 0 })

  useLayoutEffect(() => {
    if (!ref.current) return undefined
    const observer = new ResizeObserver(([entry]) => {
      const { width, height } = entry.contentRect
      setSize({ width, height })
    })
    observer.observe(ref.current)
    return () => {
      observer.disconnect()
    }
  }, [ref])

  return size
}

export default useSize
