import React, { useEffect } from 'react'
import { Container, Image, Menu, Icon, Header } from 'semantic-ui-react'
import { Link, Route, useLocation, Switch } from 'wouter'
import { useAnimate } from 'lib/hooks'
import { Provider, context, reducer } from 'lib/context'
import { Placeholder, PopulationDropdown, LevelDropdown } from 'components/ui'

const lazied = (factory) => {
  const Component = React.lazy(factory);
  let factoryPromise;

  Component.preload = () => {
    if (factoryPromise) {
      return factoryPromise;
    }

    factoryPromise = factory().then(() => undefined);
    return factoryPromise;
  };

  return Component;
}

const Tab1Page = lazied(() => import('./pages/Tab1'))
const Tab2Page = lazied(() => import('./pages/Tab2'))
// const WorldMapPage = React.lazy(() => import('./pages/WorldMap'))

const routes = {
  index: { title: 'Tendances de consommation', menuIcon: 'arrow right', path: '/', },
  profile: { title: 'Profile', menuIcon: 'arrow left', path: '/profile', },
  map: { title: 'Carte', menuIcon: null, path: '/map', },
  findByPath: path => Object.values(routes).filter(value => path === value?.path)[0]
}

const Hero = () => <Container className='hero header' />

const HeaderMenu = ({ location }) => {
  const icon = location === '/' ? 'arrow right' : 'arrow left'
  const link = location === '/' ? '/profile' : '/'
  const title = location === '/' ? 'Profils' : 'Tendances de \nconsommation'

  return (
    <Menu fluid widths={3}>
      <Container style={{ height: '110px' }}>
        <Menu.Item position="left" style={{ width: 250 }}>
          <Image src='/logo-webo.png' size='tiny' />
          <Image src='/logo-udm.svg' size='small' />
        </Menu.Item>
        <Menu.Item style={{ width: 720 }}>
          {location === '/' ? (
            <Header textAlign='center'>
              Tendances des consommations responsables
            </Header>
          ) : (
              <>
                <Placeholder as={React.Fragment}>
                  <PopulationDropdown />
                </Placeholder>
                <Placeholder as={React.Fragment}>
                  <LevelDropdown />
                </Placeholder>
              </>
            )}
        </Menu.Item>
        <Menu.Menu position="right">

          <Menu.Item href={link} as={Link} style={{ wordBreak: "break-word", width: 160 }}>
            <Icon name={icon} size='big' />
            {title}
          </Menu.Item>
        </Menu.Menu>
      </Container>
    </Menu>
  )
}

const Body = ({ location }) => {
  const { style, play } = useAnimate({
    start: { opacity: 0, transform: 'translateX(-100%)' },
    end: { opacity: 1, transform: 'translateX(50%)' },
    complete: { opacity: 1, transform: 'translateX(0%)' }
  })

  useEffect(() => play(true), [play, location])

  return (
    <div style={style}>
      <Switch location={location}>
        <Route path='/'>
          <Container>
            <Placeholder>
              <Tab1Page />
            </Placeholder>
          </Container>
        </Route>
        <Route path='/profile'>
          <Container>
            <Placeholder>
              <Tab2Page />
            </Placeholder>
          </Container>
        </Route>
      </Switch>
    </div>
  )
}

const App = () => {
  const [location] = useLocation()

  return (
    <Provider initialState={context} reducer={reducer}>
      <Hero />
      <HeaderMenu location={location} />
      <Body location={location} />
    </Provider>
  )
}

export default App
