import { useState, useEffect, useRef, useMemo } from 'react'

const useAnimate = ({
  start,
  end,
  complete,
  onComplete,
  delay = 0,
  duration = 0.3,
  easeType = 'linear'
}) => {
  const transition = useMemo(() => `all ${duration}s ${easeType} ${delay}s`, [
    duration,
    easeType,
    delay
  ])
  const [style, setStyle] = useState({ ...start, transition })
  const [isPlaying, setIsPlaying] = useState(false)
  const onCompleteTimeRef = useRef()
  const onCompleteCallbackRef = useRef(onComplete)
  const playRef = useRef()

  useEffect(() => {
    onCompleteCallbackRef.current = onComplete
  }, [onComplete])

  useEffect(
    () => () => {
      onCompleteTimeRef.current && clearTimeout(onCompleteTimeRef.current)
    },
    []
  )

  if (!playRef.current) {
    playRef.current = isPlay => {
      setStyle({ ...(isPlay ? end : start), transition })
      setIsPlaying(true)
      onCompleteTimeRef.current = setTimeout(() => {
        if (isPlay && (complete || onComplete)) {
          complete && setStyle(complete)
          onCompleteCallbackRef.current && onCompleteCallbackRef.current()
        }

        setIsPlaying(false)
      }, (delay + duration) * 1000)
    }
  }

  return {
    isPlaying,
    style,
    play: playRef.current
  }
}

export default useAnimate
