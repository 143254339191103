import { Component } from 'react'

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    error: null
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error: error.message
    }
  }

  render() {
    return this.state.hasError
      ? 'production' !== process.env.NODE_ENV
        ? this.state.error
        : this.props.fallback
      : this.props.children
  }
}

export default ErrorBoundary
