import { fetchDedupe } from '../fetch-dedupe'
import { equal } from '../utils'

const createUseResource = fetch => {
  const caches = []

  function useResource(input, init, options = 0) {
    if (typeof options === 'number') {
      // eslint-disable-next-line
      return useResource(input, init, {
        lifespan: options
      })
    }

    const { metadata = false, lifespan = 0 } = options

    for (const cache of caches) {
      if (equal(input, cache.input) && equal(init, cache.init)) {
        if (Object.prototype.hasOwnProperty.call(cache, 'error')) {
          throw cache.error
        }

        if (Object.prototype.hasOwnProperty.call(cache, 'response')) {
          if (metadata) {
            return {
              bodyUsed: cache.bodyUsed,
              contentType: cache.contentType,
              headers: cache.headers,
              ok: cache.ok,
              redirected: cache.redirected,
              response: cache.response,
              status: cache.status,
              statusText: cache.statusText,
              url: cache.url
            }
          }

          return cache.response
        }

        throw cache.fetch
      }
    }

    const cache = {
      fetch: fetch(input, init)
        .then(response => {
          cache.contentType = response.headers.get('Content-Type')

          if (metadata) {
            cache.bodyUsed = response.bodyUsed
            cache.headers = response.headers
            cache.ok = response.ok
            cache.redirected = response.redirected
            cache.status = response.status
            cache.statusText = response.statusText
          }

          return response
        })
        .then(response => {
          cache.response = response
        })
        .catch(e => {
          cache.error = e
        })
        .then(() => {
          if (lifespan > 0) {
            setTimeout(() => {
              const index = caches.indexOf(cache)

              if (index !== -1) {
                caches.splice(index, 1)
              }
            }, lifespan)
          }
        }),
      init,
      input
    }
    caches.push(cache)
    throw cache.fetch
  }

  return useResource
}

const useResource = createUseResource(fetchDedupe)

export default useResource
